<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-7 c g">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <h5 for="">{{ e("device-name") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              name="title"
              required=""
              id=""
              aria-describedby="helpId"
              :placeholder="e('write-here')"
              v-model="title"
            />
          </div>
          <div class="form-group">
            <h5 for="">{{ e("serial-number") }}</h5>
            <input
              type="text"
              class="form-control form-control-lg"
              required=""
              name="serial_number"
              id=""
              readonly
              v-model="serial_number"
            />
            <small id="helpId" class="form-text text-muted">{{
              e("cant-edit")
            }}</small>
          </div>
          <div class="form-group">
            <h5 for="">
              {{ e("timezone") }} <u>({{ e("important") }})</u>
            </h5>
            <select class="form-control form-control-lg" v-model="time_zone">
              <template v-for="tz in timezones">
                <option :value="tz.en" :key="tz.en">{{ e(tz.ar) }}</option>
              </template>
            </select>
          </div>
          <div class="form-group" v-if="user.branches">
            <h5 for="">{{ e("branche") }}</h5>
            <select class="form-control form-control-lg" v-model="branche">
              <template v-for="branche in user.branches_list">
                <option :value="branche.id" :key="branche.id">
                  {{ branche.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="addNow()">
              {{ e("edit-device") }}
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BLink, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      title: null,
      user: JSON.parse(localStorage.getItem("user")),
      timezones: timezones,
      serial_number: null,
      time_zone: null,
      branche: null,
    };
  },
  created() {
    var _g = this;
    _g.loading = true;
    $.post(api + "/devices/device", {
      jwt: _g.user.jwt,
      id: window.location.pathname.split("/edit/")[1],
    })
      .then(function (response) {
        response = JSON.parse(response);
        _g.loading = false;
        if (response.status == 100) {
          response = response.response;
          _g.title = response.title;
          _g.serial_number = response.serial_number;
          _g.time_zone = response.time_zone;
          _g.branche = response.branche;
        } else {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.$router.push("/");
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: e("error"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
        _g.loading = false;
        _g.$router.push("/");
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    addNow() {
      var _g = this;
      _g.loading = true;
      $.post(api + "/devices/edit", {
        jwt: _g.user.jwt,
        id: window.location.pathname.split("/edit/")[1],
        title: _g.title,
        time_zone: _g.time_zone,
        branche: _g.branche,
      })
        .then(function (response) {
          response = JSON.parse(response);
          if (response.status == 100) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("device-edited"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            _g.$router.push("/devices");
          } else {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e(response.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
          _g.loading = false;
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ ما",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
          _g.loading = false;
        });
    },
  },
};
</script>